import React, { useState } from 'react';

// Libaries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Popup from 'reactjs-popup';
import AsyncImageUploader from '../AsyncImageUploader';

function AsyncImageUploadPopup(props) {
  const {
    label, width, height, onSave, imgPath, bestSize,
  } = props;

  const { t } = useTranslation(['general', 'errors']);

  const [disabled, setDisabled] = useState(false);

  return (
    <Popup
      trigger={(
          imgPath ? (
            <button
              type="button"
              className="button grow_small is-block has-background-dark has-text-grey has-border-dashed-grey has-fullheight has-text-centered br-10 has-fullwidth has-fullheight p-3"
            >
              <img
                alt={label}
                src={`${process.env.REACT_APP_CDN_URL}/images${imgPath}`}
                className="br-5"
              />
              <p className="mt-2 break-word">
                {t(`general:select_${label}`)}
              </p>
            </button>
          ) : (
            <button
              type="button"
              className="button grow_small is-block has-background-dark has-text-grey has-border-dashed-grey has-fullheight has-text-centered br-10 has-fullwidth p-3 break-word"
            >
              <i className="fas fa-plus-circle mb-2" />
              <p>
                {t(`general:add_${label}`)}
              </p>
            </button>
          )
        )}
      position="top center"
      keepTooltipInside="#root"
      repositionOnResize
      closeOnDocumentClick={!disabled}
      closeOnEscape={false}
      modal
    >
      {
            (close) => (
              <div className="box p-0 has-width-400">
                <div
                  className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
                >
                  <div className="has-text-right">
                    <button
                      type="button"
                      className=" cleanButton has-text-white"
                      onClick={close}
                      disabled={disabled}
                    >
                      <i className="fas fa-times" />
                    </button>
                  </div>
                  <p
                    className="has-text-weight-semibold is-size-3"
                  >
                    {t(`general:${label}`)}
                  </p>

                  <div
                    className="br-100 has-background-primary is-absolute is-flex has-content-centered"
                    style={{
                      left: 'calc(50% - 45px)', width: '90px', height: '85px', zIndex: '999',
                    }}
                  >
                    <i className="fas fa-image is-size-2" />
                  </div>

                </div>

                <div
                  className="control px-6 py-6 has-background-black-gradient"
                >
                  <div className="pt-4">
                    <AsyncImageUploader
                      imgPath={imgPath}
                      onSave={onSave}
                      disabled={disabled}
                      setDisabled={setDisabled}
                      width={width}
                      height={height}
                    />
                  </div>
                  <div className="has-fullwidth has-text-centered">
                    <p
                      className="has-text-weight-semiboldis-size-7 mt-3 mb-2"
                    >
                      {`${t('general:ideal_size')}: (${bestSize})`}
                    </p>
                  </div>

                </div>
              </div>
            )
           }
    </Popup>
  );
}

AsyncImageUploadPopup.propTypes = {
  label: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  imgPath: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  bestSize: PropTypes.string,
};

AsyncImageUploadPopup.defaultProps = {
  width: 200,
  height: 200,
  bestSize: '200x200',
};

export default AsyncImageUploadPopup;
