import React, { useState } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';

// Changes the isPrivate property of a tournament
function TournamentThirdPlaceMatchToggle() {
  const {
    publicId, currentPhase, tournamentSettings, setHasMatchForThirdPlace,
  } = useTournament();

  const { hasMatchForThirdPlace, tournamentType } = tournamentSettings;

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { post } = useAPI();
  const { t } = useTranslation(['general', 'errors']);

  const toggleHasMatchForThirdPlace = async () => {
    try {
      setDisabled(true);
      setStatus('');

      const data = {
        hasMatchForThirdPlace: !hasMatchForThirdPlace,
      };

      await post(`/tournaments/settings/matchForThirdPlace/${publicId}`, data);

      setHasMatchForThirdPlace(!hasMatchForThirdPlace);
      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setStatus('errors:could_not_save_error');
    }
  };

  if (tournamentType !== 'single_elimination' && tournamentType !== 'double_elimination') {
    return null;
  }

  return (
    <div className="my-3">

      <div className="is-relative is-flex">
        <input
          type="checkbox"
          id="tournamentMatchForThirdPlaceToggle"
          name="tournamentMatchForThirdPlaceToggle"
          className="switch is-rtl"
          checked={hasMatchForThirdPlace}
          onChange={toggleHasMatchForThirdPlace}
          disabled={disabled || currentPhase !== 'draft'}
        />
        <label
          htmlFor="tournamentMatchForThirdPlaceToggle"
          className="checkbox has-fullwidth"
        >
          <p className="mb-1 ml-2 is-size-5 has-text-grey">
            {t('general:match_for_third_place')}
          </p>
        </label>
      </div>

      {
        status && (
        <p className="has-text-weight-semibold">
            {t(status)}
        </p>
        )
      }
    </div>
  );
}

export default TournamentThirdPlaceMatchToggle;
