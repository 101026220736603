import React, { useState, useEffect, useMemo } from 'react';

// Components
import Dropdown from 'components/utils/Dropdown';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { getSelectedGame } from 'constants/games';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Hooks
import { useSelector } from 'react-redux';

function PlatformFilterDropdown({
  game, platform, setPlatform, disabled,
}) {
  const { t } = useTranslation(['general']);

  const { language } = useSelector((state) => state.session);

  const availablePlatforms = useMemo(() => {
    const { platforms } = getSelectedGame(game);

    const preparedPlatforms = platforms.map((_platform) => ({
      label: _platform.toUpperCase(),
      value: _platform,
    }));

    return [
      { label: t('general:all_platforms'), value: '' },
      ...preparedPlatforms,
    ];
  }, [game, language]);

  const setPlatformActive = (newPlatform) => {
    stats.push(['trackEvent', 'Platform Filter', 'Platform filter selected']);
    setPlatform(newPlatform.value);
  };

  const selectedValue = useMemo(() => availablePlatforms.find((_platform) => _platform.value === platform), [platform, language]);

  if (!game) return null;

  return (
    <div className="field">

      <div className="">

        <label className="label has-text-weight-semibold is-size-7">
          {t('general:platform')}
        </label>
        <div className="has-fullwidth">
          <Dropdown
            items={availablePlatforms}
            onSelect={setPlatformActive}
            value={selectedValue}
            disabled={disabled || availablePlatforms.length === 1}
          />
        </div>
      </div>
    </div>
  );
}

PlatformFilterDropdown.propTypes = {
  game: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  setPlatform: PropTypes.func.isRequired,

  disabled: PropTypes.bool,
};

PlatformFilterDropdown.defaultProps = {
  disabled: false,
};

export default PlatformFilterDropdown;
