import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import { Link } from 'react-router-dom';

// Constants
import { LOGIN } from 'constants/routes';

// Hooks
import { useTranslation } from 'react-i18next';

function LoginPopup(props) {
  const { close, text } = props;

  const { t } = useTranslation(['general']);

  const textToShow = text || t('general:login_before_contact_details_info');

  return (
    <div className="box p-0 is-max-fullheight">
      <div
        className="has-background-primary has-text-centered p-4 pb-6 is-relative br-5"
      >
        <div className="has-text-right">
          <button
            type="button"
            className=" cleanButton has-text-white"
            onClick={close}
          >
            <i className="fas fa-times" />
          </button>
        </div>
        <p
          className="has-text-weight-semibold is-size-3 mt-"
        >
          {t('general:login_required')}
        </p>

        <div
          className="br-100 has-background-primary is-absolute is-flex has-content-centered"
          style={{
            left: 'calc(50% - 45px)', width: '90px', height: '85px',
          }}
        >
          <i className="fas fa-user is-size-2 br-5" />
        </div>

      </div>

      <div className="p-5 pb-6 br-5 has-text-centered has-background-black-gradient">
        <p
          className="has-text-weight-semibold mt-6 is-size.5 has-text-standard"
        >
          { textToShow }
        </p>

        <Link
          to={LOGIN}
          className="p-3 has-fullheight is-inline-block has-background-primary has-text-white has-text-weight-semibold br-5 mt-5"
        >
          {t('general:go_to_login')}
        </Link>

      </div>
    </div>
  );
}

LoginPopup.propTypes = {
  close: PropTypes.func,
  text: PropTypes.string,
};

LoginPopup.defaultProps = {
  close: () => {},
  text: '',
};

export default LoginPopup;
