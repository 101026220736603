import React, { useEffect, useMemo, useRef } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import MatchDisplay from './MatchDisplay';
import PanZoomWrapper from 'components/utils/PanZoomWrapper';

// Constants
const X_OFFSET = 40;
const Y_OFFSET = 80;
const UPPER_BRACKET_Y_OFFSET = 0;
// const LOWER_BRACKET_Y_OFFSET = 500;

const X_SPACE_BETWEEN_MATCHES = 80;
const Y_SPACE_BETWEEN_MATCHES = 155;
const MATCH_WIDTH = 250;
const MATCH_HEIGHT = 155;

function TournamentBracketViewer(props) {
  const { bracket, lowerBracket } = props;

  const { t } = useTranslation(['general']);

  console.log('bracket', props);

  const LOWER_BRACKET_Y_OFFSET = useMemo(() => {
    const firstRoundLength = bracket[0].matches.length;

    let fullHeightUpperBracket = firstRoundLength * MATCH_HEIGHT + Y_SPACE_BETWEEN_MATCHES * (firstRoundLength - 1) + Y_OFFSET;

    if (bracket.length === 1) {
      return fullHeightUpperBracket;
    }

    const secondRoundLength = bracket[1].matches.length;

    if (secondRoundLength > firstRoundLength) {
      return secondRoundLength * MATCH_HEIGHT + Y_SPACE_BETWEEN_MATCHES * (secondRoundLength) + Y_OFFSET;
    }

    const parentlessMatches = bracket[1].matches.filter((match) => match.parentMatches.length === 0).length;

    const amountYSpace = parentlessMatches + 1;

    fullHeightUpperBracket += parentlessMatches * MATCH_HEIGHT + Y_SPACE_BETWEEN_MATCHES * amountYSpace;

    return fullHeightUpperBracket; // Add extra spacing if needed
  }, [bracket]);

  function calculateMatchPositionY(
    match,
    matchIndex,
    roundIndex,
    childMatch,
    parentMap,
    matchPositions,
    bracketToUse,
    bracketYOffset,
  ) {
    const parents = parentMap[match.publicId];
    // if we already calculated the parent matches, we can use the average of their positions
    if (parents && parents.length > 0) {
      const parentPositions = parents.map(
        (parentMatch) => (matchPositions[parentMatch.publicId] ? matchPositions[parentMatch.publicId].positionY : 0),
      );

      const positionY = parentPositions.reduce((sum, posY) => sum + posY, 0) / parentPositions.length;

      return positionY;
    }

    if (roundIndex === 0) {
      if (childMatch && childMatch.match > matchIndex) {
        matchIndex = childMatch.match - 1;
      }

      const positionY = matchIndex * (MATCH_HEIGHT + Y_SPACE_BETWEEN_MATCHES) + bracketYOffset + Y_OFFSET;
      return positionY;
    }

    // if there are no parents, orient on the sibling
    const sibling = bracketToUse[roundIndex].matches[matchIndex - 1];

    if (sibling && matchPositions[sibling.publicId]) {
      const siblingPosition = matchPositions[sibling.publicId].positionY;

      const positionY = siblingPosition + (MATCH_HEIGHT + Y_SPACE_BETWEEN_MATCHES);
      return positionY;
    }

    // finally, if this is the third place match, we need to adjust the position
    if (match.stage === 'third_place') {
      matchIndex = 1.5;
    }

    const positionY = matchIndex * (MATCH_HEIGHT + Y_SPACE_BETWEEN_MATCHES) + bracketYOffset + Y_OFFSET;

    return positionY;
  }

  const renderedBracket = useMemo(() => {
    // Step 1: Build matchMap and parentMap
    const matchMap = {};
    const parentMap = {};

    const allRounds = [...bracket, ...lowerBracket];

    allRounds.forEach((round) => {
      const { matches } = round;
      matches.forEach((match) => {
        matchMap[match.publicId] = match;

        if (match.childMatch) {
          if (!parentMap[match.childMatch]) {
            parentMap[match.childMatch] = [];
          }
          parentMap[match.childMatch].push(match);
        }
      });
    });

    // Step 2: Assign positionY to matches
    const matchPositions = {};

    // Step 3: Render matches using matchPositions
    const renderedMatches = [];
    const renderedLines = [];

    // Important: We first need to calculate the lower bracket because it can have an impact on the upper bracket (especially on final / grandfinal positions)
    lowerBracket.forEach((round, roundIndex) => {
      const { matches } = round;
      matches.forEach((match, matchIndex) => {
        // Calculate positions
        const positionX = roundIndex * (MATCH_WIDTH + X_SPACE_BETWEEN_MATCHES) + X_OFFSET;
        const positionY = calculateMatchPositionY(
          match,
          matchIndex,
          roundIndex,
          match.childMatch ? matchMap[match.childMatch] : null,
          parentMap,
          matchPositions,
          lowerBracket,
          LOWER_BRACKET_Y_OFFSET,
        );

        matchPositions[match.publicId] = { positionX, positionY };

        // Render stage name
        if (matchIndex === 0) {
          const roundNumber = match.stage.includes('round') ? ` ${matches[0].round + 1}` : '';
          renderedMatches.push(
            <div
              style={{
                position: 'absolute',
                width: MATCH_WIDTH,
                left: positionX,
                top: positionY - Y_SPACE_BETWEEN_MATCHES / 4,
                textAlign: 'center',
              }}
              key={`stage_name_lower_${roundIndex}`}
            >
              <p className="has-text-weight-bold">
                {t(`general:${match.stage}`) + roundNumber}
              </p>
            </div>,
          );
        }

        const matchNumber = lowerBracket
          .slice(0, roundIndex)
          .reduce((acc, curr) => acc + curr.matches.length, 0)
          + matchIndex
          + 1;

        // Render MatchDisplay
        renderedMatches.push(
          <MatchDisplay
            match={match}
            key={match.publicId}
            matchNumber={matchNumber}
            positionX={positionX}
            positionY={positionY}
          />,
        );
      });
    });

    bracket.forEach((round, roundIndex) => {
      const { matches } = round;
      matches.forEach((match, matchIndex) => {
        const positionX = roundIndex * (MATCH_WIDTH + X_SPACE_BETWEEN_MATCHES) + X_OFFSET;

        const positionY = calculateMatchPositionY(
          match,
          matchIndex,
          roundIndex,
          match.childMatch ? matchMap[match.childMatch] : null,
          parentMap,
          matchPositions,
          bracket,
          UPPER_BRACKET_Y_OFFSET,
        );

        matchPositions[match.publicId] = { positionX, positionY };

        const isFinal = match.stage === 'final' || match.stage === 'grand_final';
        // Render stage name
        if (matchIndex === 0 && !isFinal) {
          const roundNumber = match.stage.includes('round') ? ` ${matches[0].round + 1}` : '';
          renderedMatches.push(
            <div
              style={{
                position: 'absolute',
                width: MATCH_WIDTH,
                left: positionX,
                textAlign: 'center',
                top: positionY - Y_SPACE_BETWEEN_MATCHES / 4,
              }}
              key={`stage_name_upper_${roundIndex}`}
            >
              <p className="has-text-weight-bold">
                {t(`general:${match.stage}`) + roundNumber}
              </p>
            </div>,
          );
        }

        // Calculate matchNumber if needed
        const matchNumber = bracket
          .slice(0, roundIndex)
          .reduce((acc, curr) => acc + curr.matches.length, 0)
          + matchIndex
          + 1;

        renderedMatches.push(
          <MatchDisplay
            match={match}
            key={match.publicId}
            matchNumber={matchNumber}
            positionX={positionX}
            positionY={positionY}
          />,
        );
      });
    });

    // Step 4: Render lines
    const lowerBracketFirstAllRounds = allRounds.sort((a, b) => (a.stage.includes('lower') ? -1 : 1));

    lowerBracketFirstAllRounds.forEach((round) => {
      const { matches } = round;
      matches.forEach((match) => {
        const { positionX, positionY } = matchPositions[match.publicId];

        if (match.childMatch) {
          const childMatch = matchMap[match.childMatch];
          if (childMatch) {
            const { positionX: childPositionX, positionY: childPositionY } = matchPositions[childMatch.publicId];

            // Calculate start and end points
            const startX = positionX + MATCH_WIDTH; // Right edge of parent match
            const startY = positionY + MATCH_HEIGHT / 2 + 22;
            const endX = childPositionX; // Left edge of child match
            const endY = childPositionY + MATCH_HEIGHT / 2 + 22;
            // let midX = startX + (endX - startX) / 2;
            const midX = endX - X_SPACE_BETWEEN_MATCHES / 2;

            // Create path data
            const pathData = `M${startX},${startY} H${midX} V${endY} H${endX}`;

            let stroke = 'white';
            if (match.stage.includes('upper')) {
              stroke = 'green';
            }

            if (match.stage.includes('lower')) {
              stroke = 'red';
            }

            // Create a path element
            renderedLines.push(
              <path
                key={`${match.publicId}-${childMatch.publicId}`}
                d={pathData}
                stroke={stroke}
                strokeWidth={1}
                fill="none"
              />,
            );
          }
        }
      });
    });

    return { renderedMatches, renderedLines, matchPositions };
  }, [bracket, lowerBracket, t]);

  const completeWidth = useMemo(() => {
    const upperBracketWidth = (bracket.length - 1) * (MATCH_WIDTH + X_SPACE_BETWEEN_MATCHES) + MATCH_WIDTH;
    return Math.max(upperBracketWidth) + X_OFFSET * 2;
  }, [bracket]);

  const completeHeight = useMemo(() => {
    const matchPositionsArray = Object.values(renderedBracket.matchPositions);
    const sortedPositions = matchPositionsArray.slice().sort((a, b) => a.positionY - b.positionY);

    if (!sortedPositions.length) return 0;

    const highestY = sortedPositions[sortedPositions.length - 1].positionY;
    const bracketHeight = highestY + MATCH_HEIGHT + Y_OFFSET;

    return bracketHeight;
  }, [bracket]);

  return (
    <PanZoomWrapper>
      <div
        className=""
      >
        <div
          className="is-relative"
          style={{
            width: completeWidth,
            height: completeHeight,
          }}
        >

          <div
            className="is-relative"
            style={{ zIndex: 99 }}
          >
            {renderedBracket.renderedMatches}
          </div>

          <svg
            className="is-absolute"
            style={{
              top: 0,
              left: 0,
              zIndex: 1,
              width: completeWidth,
              height: completeHeight,
            }}
          >
            {renderedBracket.renderedLines}
          </svg>

        </div>
      </div>
    </PanZoomWrapper>

  );
}

/*
<svg
            className="is-absolute"
            style={{
              top: 0, left: 0, zIndex: 1, width: completeWidth, height: completeHeight,
            }}
          >
            {renderedLines}
          </svg>
*/

TournamentBracketViewer.propTypes = {
  bracket: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  lowerBracket: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TournamentBracketViewer;
