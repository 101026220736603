import React, { useMemo, useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Constants
import * as routes from 'constants/routes';

// Components
import LanguageSelector from 'components/utils/LanguageSelector';
import TimezoneSelector from 'components/utils/TimezoneSelector';
import { Link, useLocation } from 'react-router-dom';

// Assets
import icon from 'assets/images/base/icon_no_text.png';
import iconFont from 'assets/images/base/scrimps-text-font.png';

// Styles
import CardPicDisplay from 'components/utils/CardPicDisplay';
import NavigationRegionSelector from './NavigationRegionSelector';

function Navigation() {
  const [isActive, setIsActive] = useState(false);

  const { isAuthenticated } = useAuth0();

  const toggleNavbarMobile = () => {
    setIsActive(!isActive);
  };

  const hideNavbar = () => {
    setIsActive(false);
  };

  if (!isAuthenticated) {
    return (
      <NavigationNoAuth
        isActive={isActive}
        toggleNavbarMobile={toggleNavbarMobile}
        hideNavbar={hideNavbar}
      />
    );
  }

  return (
    <NavigationAuth
      isActive={isActive}
      toggleNavbarMobile={toggleNavbarMobile}
      hideNavbar={hideNavbar}
    />
  );
}

function NavigationNoAuth(props) {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation(['general', 'hotscrimps', 'playerboard']);

  const { isActive, toggleNavbarMobile, hideNavbar } = props;

  const location = useLocation();
  const { pathname } = location;

  return (
    <aside className="menu is-fullheight has-overflow-y-auto box br-0 has-width-250 px-0 pt-5 pb-2 is-flex flex-direction-column">
      <div className="has-text-centered mb-5">
        <Link
          to={routes.LANDING}
          className="is-flex has-content-centered px-3"
        >
          <img src={icon} alt="" width="64" height="64" className="has-max-fullheight" />
          <img src={iconFont} alt="" height="64" className="has-max-fullheight has-max-width-100" />
        </Link>
      </div>
      <Link
        className={`has-fullwidth is-block is-size-6 my-1 py-1 px-4 has-text-weight-semibold ${pathname === routes.LANDING ? 'has-border-right-primary-bold' : ''}`}
        to={routes.LANDING}
      >
        <i className="fas fa-home mr-3" />
        {`${t('general:Home').toUpperCase()}`}
      </Link>
      <Link
        className={`has-fullwidth is-block is-size-6 my-1 py-1 px-4 has-text-weight-semibold ${pathname === routes.HOT_SCRIMPS ? 'has-border-right-primary-bold' : ''}`}
        to={routes.HOT_SCRIMPS}
      >
        <i className="fas fa-search mr-3" />
        {`LFS - ${t('hotscrimps:hotscrimps').toUpperCase()}`}
      </Link>
      <Link
        className={`has-fullwidth is-block is-size-6 my-1 py-1 px-4 has-text-weight-semibold ${pathname === routes.PLAYERBOARD ? 'has-border-right-primary-bold' : ''}`}
        to={routes.PLAYERBOARD}
      >
        <i className="fas fa-search-plus mr-3" />
        {`LFT - ${t('playerboard:playerboard').toUpperCase()}`}
      </Link>

      <div className="has-border-top-grey my-4 mx-5 has-margin-auto" />

      <Link
        className={`has-fullwidth is-block is-size-6 my-1 py-1 px-4 has-text-weight-semibold ${pathname === routes.PLAYERS_LIST ? 'has-border-right-primary-bold' : ''}`}
        to={routes.PLAYERS_LIST}
      >
        <i className="fas fa-users mr-3" />
        {`${t('general:player_list').toUpperCase()}`}
      </Link>

      <Link
        className={`has-fullwidth is-block is-size-6 my-1 py-1 px-4 has-text-weight-semibold ${pathname === routes.TEAMS_LIST ? 'has-border-right-primary-bold' : ''}`}
        to={routes.TEAMS_LIST}
      >
        <i className="fas fa-user-friends mr-3" />
        {`${t('general:teams_list').toUpperCase()}`}
      </Link>

      <Link
        className={`has-fullwidth is-block is-size-6 my-1 py-1 px-4 has-text-weight-semibold ${pathname === routes.ORGA_LIST ? 'has-border-right-primary-bold' : ''}`}
        to={routes.ORGA_LIST}
      >
        <i className="fas fa-sitemap mr-3" />
        {`${t('general:orgas').toUpperCase()}`}
      </Link>

      <Link
        className={`has-fullwidth is-block is-size-6 my-1 py-1 px-4 has-text-weight-semibold ${pathname === routes.TOURNAMENT_LIST ? 'has-border-right-primary-bold' : ''}`}
        to={routes.TOURNAMENT_LIST}
      >
        <i className="fas fa-chess mr-3" />
        {`${t('general:tournaments').toUpperCase()}`}
      </Link>

      <div className="has-border-top-grey mt-4 mx-5" />

      <Link
        className={`has-fullwidth is-block is-size-6 mb-2 mt-5 py-1 px-4 has-text-weight-semibold ${pathname === routes.RECIPE_LIST ? 'has-border-right-primary-bold' : ''}`}
        to={routes.RECIPE_LIST}
      >
        <i className="fas fa-utensils mr-3" />
        {`${t('general:recipes').toUpperCase()}`}
      </Link>

      <div className="mx-5 mt-4 px-3 py-4 has-border-top-grey has-border-bottom-grey">
        <TimezoneSelector />

        <div className="my-4">
          <LanguageSelector />
        </div>

        <NavigationRegionSelector />
      </div>

      <div className="has-margin-top-auto">

        <div className="has-text-centered mb-5">
          <Link
            to={routes.LOGIN}
            type="button"
            className="button boxshadow has-border-black-light has-background-primary has-text-weight-semibold"
          >
            {t('general:login')}
          </Link>
        </div>

      </div>
    </aside>
  );
}

NavigationNoAuth.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggleNavbarMobile: PropTypes.func.isRequired,
  hideNavbar: PropTypes.func.isRequired,
};

/*
<Link
              className="navbar-item button is-rounded has-background-secondary px-4 has-text-weight-semibold has-margin-auto mx-1 is-inline-block"
              to={routes.HOME}
              onClick={hideNavbar}
            >
              Slip Cards
            </Link>
*/
function NavigationAuth(props) {
  const { logout } = useAuth0();
  const { t } = useTranslation(['general', 'hotscrimps', 'playerboard']);

  const { language, name, cardPic } = useSelector((state) => state.player.player);

  const { isActive, toggleNavbarMobile, hideNavbar } = props;

  const location = useLocation();
  const { pathname } = location;

  const logUserOut = async () => {
    try {
      await logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <aside
      className="menu is-fullheight has-overflow-y-auto box br-0 has-width-250 px-0 pt-5 pb-2 is-flex flex-direction-column has-background-dark-with-gradient"
    >
      <div className="has-text-centered mb-5">
        <Link
          to={routes.LANDING}
          className="is-flex has-content-centered px-3"
        >
          <img src={icon} alt="" width="64" height="64" className="has-max-fullheight" />
          <img src={iconFont} alt="" height="64" className="has-max-fullheight has-max-width-100" />
        </Link>
      </div>
      <Link
        className={`has-fullwidth is-block is-size-6 my-1 py-1 px-4 has-text-weight-semibold ${pathname === routes.LANDING ? 'has-border-right-primary-bold' : ''}`}
        to={routes.LANDING}
      >
        <i className="fas fa-home mr-3" />
        {`${t('general:Home').toUpperCase()}`}
      </Link>
      <Link
        className={`has-fullwidth is-block is-size-6 my-1 py-1 px-4 has-text-weight-semibold ${pathname === routes.HOT_SCRIMPS ? 'has-border-right-primary-bold' : ''}`}
        to={routes.HOT_SCRIMPS}
      >
        <i className="fas fa-search mr-3" />
        {`LFS - ${t('hotscrimps:hotscrimps').toUpperCase()}`}
      </Link>
      <Link
        className={`has-fullwidth is-block is-size-6 my-1 py-1 px-4 has-text-weight-semibold ${pathname === routes.PLAYERBOARD ? 'has-border-right-primary-bold' : ''}`}
        to={routes.PLAYERBOARD}
      >
        <i className="fas fa-search-plus mr-3" />
        {`LFT - ${t('playerboard:playerboard').toUpperCase()}`}
      </Link>

      <div className="has-border-top-grey my-4 mx-5 has-margin-auto" />

      <Link
        className={`has-fullwidth is-block is-size-6 my-1 py-1 px-4 has-text-weight-semibold ${pathname === routes.PLAYERS_LIST ? 'has-border-right-primary-bold' : ''}`}
        to={routes.PLAYERS_LIST}
      >
        <i className="fas fa-users mr-3" />
        {`${t('general:player_list').toUpperCase()}`}
      </Link>

      <Link
        className={`has-fullwidth is-block is-size-6 my-1 py-1 px-4 has-text-weight-semibold ${pathname === routes.TEAMS_LIST ? 'has-border-right-primary-bold' : ''}`}
        to={routes.TEAMS_LIST}
      >
        <i className="fas fa-user-friends mr-3" />
        {`${t('general:teams_list').toUpperCase()}`}
      </Link>

      <Link
        className={`has-fullwidth is-block is-size-6 my-1 py-1 px-4 has-text-weight-semibold ${pathname === routes.ORGA_LIST ? 'has-border-right-primary-bold' : ''}`}
        to={routes.ORGA_LIST}
      >
        <i className="fas fa-sitemap mr-3" />
        {`${t('general:orgas').toUpperCase()}`}
      </Link>

      <Link
        className={`has-fullwidth is-block is-size-6 my-1 py-1 px-4 has-text-weight-semibold ${pathname === routes.TOURNAMENT_LIST ? 'has-border-right-primary-bold' : ''}`}
        to={routes.TOURNAMENT_LIST}
      >
        <i className="fas fa-chess mr-3" />
        {`${t('general:tournaments').toUpperCase()}`}
      </Link>

      <div className="has-border-top-grey mt-4 mx-5 has-margin-auto" />

      <Link
        className={`has-fullwidth is-block is-size-6 my-2 py-1 px-4 has-text-weight-semibold ${pathname === routes.RECIPE_LIST ? 'has-border-right-primary-bold' : ''}`}
        to={routes.RECIPE_LIST}
      >
        <i className="fas fa-utensils mr-3" />
        {`${t('general:recipes').toUpperCase()}`}
      </Link>

      <div className="has-margin-top-auto">

        <div className="mx-5 px-3 py-5 has-border-top-grey has-border-bottom-grey">
          <TimezoneSelector />

          <div className="my-4">
            <LanguageSelector />
          </div>

          <NavigationRegionSelector />
        </div>

        <div className="is-flex has-content-centered mt-4 mb-5">

          <div className="has-text-centered pt-2 is-relative">
            <Link
              to={routes.PLAYER_PROFILE}
              className="is-inline-block"
            >
              <div
                className="has-max-width-100 br-10 has-border-black-light boxshadow has-margin-auto"
              >
                <CardPicDisplay img={cardPic} />

              </div>
              <div className="is-flex has-content-centered mt-3">
                <i className="fas fa-circle has-text-primary mr-2" style={{ fontSize: '7px', marginTop: '2px' }} />
                <p
                  className="is-inline-block is-size-6 py-0 has-text-weight-semibold has-text-standard"
                >
                  {name}
                </p>
              </div>
            </Link>
          </div>

          <div className="ml-5">
            <button
              className="button has-no-background has-width-40 has-height-40 p-2 is-block"
              type="button"
              onClick={logUserOut}
            >
              <i className="fas fa-power-off has-text-grey is-size-5" />
            </button>
            <Link
              className="button has-no-background has-width-40 has-height-40 p-2 is-block mt-1"
              to={routes.PROFILE}
            >
              <i className="fas fa-user-cog has-text-grey is-size-5" />
            </Link>
          </div>
        </div>

      </div>
    </aside>

  );
}

NavigationAuth.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggleNavbarMobile: PropTypes.func.isRequired,
  hideNavbar: PropTypes.func.isRequired,
};

export default Navigation;
