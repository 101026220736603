import React from 'react';

// Libraries
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
// import { createBrowserHistory } from 'history';
import stats from 'analytics/matomo';
import { HelmetProvider, Helmet } from 'react-helmet-async';

// Constants
import * as routes from 'constants/routes';

// Components

// Teams
import TeamProfile from 'components/Teams/TeamProfile';
import TeamList from 'components/Teams/TeamList';
import TeamAcceptInvitePage from 'components/Teams/TeamAcceptInvitePage';

// Orgas
import OrgaPage from 'components/orgas/OrgaPage';
import OrgaList from 'components/orgas/OrgaList';
import OrgaMemberInvitationPage from 'components/orgas/OrgaMemberInvitationPage';
import OrgaTeamInvitationPage from 'components/orgas/OrgaTeamInvitationPage';

// Player
import PlayerProfile from 'components/Players/PlayerProfile';
import PlayerList from 'components/Players/PlayerList';
import PlayerDisplayProfile from 'components/Players/PlayerDisplayProfile';

// Providers
import DataProvider from 'components/providers/DataProvider';
import SocketProvider from 'components/providers/SocketProvider';
import ChatProvider from 'components/providers/ChatProvider';
import FriendListProvider from 'components/friends/FriendListProvider';
import NotificationProvider from 'components/Notifications/NotificationProvider';

// Layout / basic components
import CookieHandler from 'components/layout/CookieHandler';
import DesktopOnlyNotice from 'components/layout/DesktopOnlyNotice';
import Footer from 'components/layout/Footer';
import Onboarding from 'components/layout/Onboarding';
import Landing from 'components/layout/Landing';
import Navigation from 'components/layout/Navigation';
import NotFound from 'components/layout/NotFound';
import Login from 'components/auth/Login';
import Home from 'components/home/Home';
import Imprint from 'components/layout/Imprint';
import PrivacyPolicy from 'components/layout/PrivacyPolicy';
import ScrollToTop from 'components/utils/ScrollToTop';
import SocialSidebar from 'components/layout/SocialSidebar';

// Matches
import MatchFinderContainer from 'components/matches/MatchFinderContainer';
import MatchDisplayContainer from 'components/matches/MatchDisplayContainer';

// Card
import CardCreatorContainer from 'components/cards/CardCreatorContainer';

// User
import UserProfile from 'components/user/UserProfile';

// Playerboard
import Playerboard from 'components/Playerboard';

// HotScrimps
import HotScrimpsEntryList from 'components/HotScrimps/HotscrimpsEntryList';
import HotscrimpsDisplayPage from 'components/HotScrimps/HotscrimpsDisplayPage';
import HotscrimpsRequestPage from 'components/HotScrimps/HotscrimpsRequestPage';

// Recipes
import RecipeList from 'components/recipes/RecipeList';
import RecipePage from 'components/recipes/RecipePage';

// Tournaments
import TournamentList from 'components/tournaments/TournamentList';
import TournamentPage from 'components/tournaments/TournamentPage';

// Initialize languages
import './locales/i18n';
import TournamentAcceptInvitePage from 'components/tournaments/TournamentAcceptInvitePage';
import TournamentTOS from 'components/tournaments/TournamentTOS';
import TOS from 'components/layout/TOS';

// const history = stats.connectToHistory(createBrowserHistory());

stats.push(['requireConsent']);
stats.push(['enableHeartBeatTimer']);
stats.push(['trackPageView']);

// disable console.log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => { };
}

function App() {
  return (
    <Router>
      <HelmetProvider>
        <DataProvider>
          <SocketProvider>
            <NotificationProvider>
              <ChatProvider>
                <FriendListProvider>
                  <Helmet>
                    <title>Scrimps - Never scrim alone</title>
                    <meta name="description" content="Deine Plattform rund um Scrims, Teams und Mates. Nutze die verschiedenen Module, um Mitspieler zu finden, Teams zu managen und Scrims zu organisieren." />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta property="og:title" content="Scrimps - Never scrim alone" />
                    <meta property="og:description" content="Deine Plattform rund um Scrims, Teams und Mates. Nutze die verschiedenen Module, um Mitspieler zu finden, Teams zu managen und Scrims zu organisieren." />
                    <meta property="og:url" content="https://scrimps.wtf" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="https://scrimps.wtf/static/media/icon_no_text.91ff1f13.png" />
                    <link rel="icon" href="/favicon.ico" />
                  </Helmet>

                  <ScrollToTop />
                  <DesktopOnlyNotice />
                  <CookieHandler />

                  <div className="columns is-mobile">

                    <div className="column p-0 is-narrow has-fullwidth has-max-width-250 has-fullheight">
                      <div className="is-fixed">
                        <Navigation />
                      </div>
                    </div>

                    <div className="column p-0 has-fullwidth" id="contentWrapper">
                      <Routes>
                        <Route
                          exact
                          path={routes.LANDING}
                          element={<Landing />}
                        />
                        <Route
                          exact
                          path={routes.LOGIN}
                          element={<Login />}
                        />
                        <Route
                          exact
                          path={routes.ONBOARDING}
                          element={<Onboarding />}
                        />

                        <Route
                          exact
                          path={routes.HOME}
                          element={<Home />}
                        />

                        <Route
                          exact
                          path={routes.IMPRINT}
                          element={<Imprint />}
                        />

                        <Route
                          exact
                          path={routes.PRIVACY}
                          element={<PrivacyPolicy />}
                        />

                        <Route
                          exact
                          path={routes.TOS}
                          element={<TOS />}
                        />

                        <Route
                          exact
                          path={routes.PROFILE}
                          element={<UserProfile />}
                        />

                        <Route
                          exact
                          path={routes.PLAYERS_LIST}
                          element={<PlayerList />}
                        />

                        <Route
                          exact
                          path={routes.PLAYER_PROFILE}
                          element={<PlayerProfile />}
                        />

                        <Route
                          exact
                          path={routes.PLAYER_PROFILE_PAGE}
                          element={<PlayerDisplayProfile />}
                        />

                        <Route
                          exact
                          path={routes.TEAMS_LIST}
                          element={<TeamList />}
                        />

                        <Route
                          exact
                          path={routes.TEAM_PROFILE_PAGE}
                          element={<TeamProfile />}
                        />

                        <Route
                          exact
                          path={routes.TEAM_ACCEPT_INVITE_PAGE}
                          element={<TeamAcceptInvitePage />}
                        />

                        <Route
                          exact
                          path={routes.CARD_CREATE}
                          element={<CardCreatorContainer />}
                        />
                        <Route
                          exact
                          path={routes.CARD_EDIT}
                          element={<CardCreatorContainer />}
                        />

                        <Route
                          exact
                          path={routes.MATCHES_ID}
                          element={<MatchDisplayContainer />}
                        />

                        <Route
                          exact
                          path={routes.MATCHES_SEARCH}
                          element={<MatchFinderContainer />}
                        />

                        <Route
                          exact
                          path={routes.MATCHES_SEARCH_ID}
                          element={<MatchFinderContainer />}
                        />

                        <Route
                          exact
                          path={routes.HOT_SCRIMPS}
                          element={<HotScrimpsEntryList />}
                        />

                        <Route
                          exact
                          path={routes.HOT_SCRIMPS_REQUEST_PAGE}
                          element={<HotscrimpsRequestPage />}
                        />

                        <Route
                          exact
                          path={routes.HOT_SCRIMPS_PAGE}
                          element={<HotscrimpsDisplayPage />}
                        />

                        <Route
                          exact
                          path={routes.ORGA_LIST}
                          element={<OrgaList />}
                        />

                        <Route
                          exact
                          path={routes.ORGA_PAGE_ID}
                          element={<OrgaPage />}
                        />

                        <Route
                          exact
                          path={routes.ORGA_MEMBER_INVITE_PAGE_ID}
                          element={<OrgaMemberInvitationPage />}
                        />

                        <Route
                          exact
                          path={routes.ORGA_TEAM_INVITE_PAGE_ID}
                          element={<OrgaTeamInvitationPage />}
                        />

                        <Route
                          exact
                          path={routes.PLAYERBOARD}
                          element={<Playerboard />}
                        />

                        <Route
                          exact
                          path={routes.RECIPE_LIST}
                          element={<RecipeList />}
                        />

                        <Route
                          exact
                          path={routes.RECIPE_PAGE_ID}
                          element={<RecipePage />}
                        />

                        <Route
                          exact
                          path={routes.TOURNAMENT_LIST}
                          element={<TournamentList />}
                        />

                        <Route
                          exact
                          path={routes.TOURNAMENT_PAGE_ID}
                          element={<TournamentPage />}
                        />

                        <Route
                          exact
                          path={routes.TOURNAMENT_ACCEPT_STAFF_INVITE_PAGE_ID}
                          element={<TournamentAcceptInvitePage />}
                        />

                        <Route
                          exact
                          path={routes.TOURNAMENT_TOS}
                          element={<TournamentTOS />}
                        />

                        <Route
                          path="*"
                          render={() => <NotFound />}
                        />
                      </Routes>

                      <Footer />
                    </div>

                    <SocialSidebar />

                  </div>
                </FriendListProvider>
              </ChatProvider>
            </NotificationProvider>
          </SocketProvider>
        </DataProvider>

      </HelmetProvider>
    </Router>
  );
}

export default App;
