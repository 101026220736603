import React, { useState, useEffect } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';

// Libraries
import { DateTime } from 'luxon';

// HOCs
import withAuthentication from 'components/hocs/withAuthentication';

// Actions
import { setPlayer } from 'store/player';

// Components
import NameEditor from './NameEditor';
import NationalitySelector from './NationalitySelector';
import CardPicEditor from './CardPicEditor';
import ContactInfoEditor from './ContactInfoEditor';
import DescriptionEditor from './DescriptionEditor';
import PlayerIdDisplay from './PlayerIdDisplay';
import RanksEditor from './RanksEditor';
import PrivateToggler from './PrivateToggler';

function PlayerProfile() {
  const { player } = useSelector((state) => state.player);

  const dispatch = useDispatch();

  const { get, post } = useAPI();

  const { t } = useTranslation(['general', 'errors']);

  const [isLoading, setLoading] = useState(true);
  const [status, setStatus] = useState('');

  const loadPlayer = async () => {
    try {
      setLoading(true);
      const _player = await get('/player');

      dispatch(setPlayer(_player));

      setLoading(false);
    } catch (e) {
      setStatus(t('errors:data_load_error'));
    }
  };

  useEffect(() => {
    loadPlayer();
  }, []);

  if (isLoading) {
    return (
      <div className="is-min-fullheight is-flex has-content-centered flex-direction-column">
        <p className="mt-2 has-text-weight-semibold is-size-5">
          { status || t('general:loading') }
        </p>
      </div>
    );
  }

  const {
    name, profilePic, nationality, engagement, playerId,
  } = player;

  return (
    <div className="is-min-fullheight p-0 m-0 is-relative">
      <div
        className="has-gradient-primary has-fullwidth is-relative"
        style={{ height: '170px' }}
      />

      <div className="columns is-centered is-mobile is-multiline m-0 px-0 is-min-fullheight">

        <div className="column is-10-desktop is-12-tablet is-11-mobile p-0">

          <div
            className="is-relative"
          >

            <div
              className="is-relative mb-6"
              style={{ marginTop: '-110px' }}
            >
              <div
                className="columns px-6"
              >
                <div className="column is-narrow">
                  <CardPicEditor />
                </div>

                <div className="column">
                  <div className="is-flex has-content-centered-vertically">
                    <p
                      className="has-text-weight-semibold is-size-3-desktop is-size-4-tablet is-size-5-mobile has-text-standard"
                    >
                      {name}
                    </p>
                    <div className="ml-1">
                      <NationalitySelector />
                    </div>
                  </div>
                  <p
                    className="is-size-5-desktop is-size-5-tablet is-size-6-mobile has-text-weight-semibold has-text-dark mt-1"
                  >
                    {t('general:player_profile')}
                  </p>

                  <div className="mt-2">
                    <div className="columns">
                      <div className="column" />
                      <div className="column is-narrow has-text-right has-height-200" />
                    </div>
                  </div>
                </div>

                <div className="column is-narrow">
                  <PrivateToggler />
                </div>
              </div>
            </div>

          </div>

          <div
            className="px-6 pb-6"
          >

            <div className="px-3">

              <div className="">

                <PlayerIdDisplay />

                <div className="mt-6">
                  <NameEditor />
                </div>

                <div className="my-6">
                  <ContactInfoEditor />

                </div>

                <div className="mb-6">
                  <RanksEditor />
                </div>

                <DescriptionEditor />

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  );
}

// <EngagementSelector />

export default withAuthentication(PlayerProfile);
