import React, { useState, useEffect, useRef } from 'react';

// Libaries
import PropTypes from 'prop-types';

// Hooks
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

// Components
import AvatarEditor from 'react-avatar-editor';

/**
 * Drag & Drop Zone to upload images
 */
function AsyncImageUploader(props) {
  const {
    imgPath, onSave, width, height, disabled, setDisabled,
  } = props;

  const [imgUrl, setImgUrl] = useState('');
  const [status, setStatus] = useState('');

  const ref = useRef();

  const { t } = useTranslation(['general', 'errors']);

  useEffect(() => {
    if (imgPath) {
      setImgUrl(`${process.env.REACT_APP_CDN_URL}/images${imgPath}`);
    }

    return () => {
      URL.revokeObjectURL(imgUrl);
    };
  }, [imgPath]);

  const getCroppedImage = () => {
    if (!ref || !ref.current) {
      throw new Error('Canvas not ready');
    }

    const canvas = ref.current.getImage();

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        // returning an error
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }

        resolve(blob);
      }, 'image/png');
    });
  };

  const saveImage = async () => {
    try {
      setStatus('');
      setDisabled(true);

      const image = await getCroppedImage();
      await onSave(image);

      setDisabled(false);
      setStatus(`${t('general:saved_successfully')}`);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setStatus(`${t('errors:save_image_error')}`);
    }
  };

  const onDrop = (file) => {
    // if file has been rejected, data will be empty
    if (file.length === 0) return;

    setStatus('');

    URL.revokeObjectURL(imgUrl);
    const url = URL.createObjectURL(file[0]);
    setImgUrl(url);
  };

  const onDropRejected = () => {
    setStatus(t('errors:invalid_image_error'));
  };

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    noDrag: true,
    onDrop,
    maxFiles: 1,
    // max 2 MB
    maxSize: 2097152,
    onDropRejected,
  });

  const hasUploadedImage = imgUrl.startsWith('blob:');
  const hasImage = imgUrl !== '' && !hasUploadedImage;

  return (
    <div
      className="control has-fullwidth"
    >
      <div className="is-flex has-content-centered">

        {
            hasUploadedImage
              && (
              <div className="has-border-grey is-inline-flex has-overflow-hidden p-0 br-10 mb-5">
                <AvatarEditor
                  image={imgUrl}
                  width={width}
                  height={height}
                  disabled={!hasUploadedImage}
                  border={hasUploadedImage ? 10 : 0}
                  color={[205, 205, 205, 0.5]} // RGBA
                  scale={1}
                  rotate={0}
                  ref={ref}
                />
              </div>
              )
            }

        {
        hasImage && (
          <div className="has-border-grey is-inline-flex has-overflow-hidden p-0 br-10 mb-5">
            <img src={imgUrl} alt="profile" className="" />
          </div>
        )
       }

      </div>

      <div {...getRootProps({
        className: 'has-cursor-pointer has-text-centered',
      })}
      >

        <input {...getInputProps()} />

        <div className="has-text-centered has-fullwidth">
          {
            hasUploadedImage
              ? (
                <button
                  type="button"
                  className="cleanButton grow_small has-text-white has-text-weight-semibold"
                  style={{ backgroundColor: '#086842' }}
                  disabled={disabled}
                >
                  {t('general:select_different_image')}
                </button>
              )
              : (
                <button
                  type="button"
                  className="button is-size-5-desktop has-background-primary grow_small has-text-weight-semibold boxshadow"
                  style={{ backgroundColor: '#086842' }}
                  disabled={disabled}
                >
                  {t('general:select_image')}
                </button>
              )
            }
        </div>
      </div>
      {
        hasUploadedImage
          && (
          <div className="has-fullwidth has-text-centered">
            <button
              type="button"
              className="button is-size-5-desktop has-background-primary grow_small has-text-weight-semibold mt-5 boxshadow"
              style={{ backgroundColor: '#086842' }}
              disabled={disabled}
              onClick={saveImage}
            >
              {t('general:save')}
            </button>
          </div>
          )
      }
      {
        status && (
          <div className="has-text-centered mt-4">
            <p className="has-text-weight-semibold">
              { status }
            </p>
          </div>
        )
      }
    </div>
  );
}

AsyncImageUploader.propTypes = {
  imgPath: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

AsyncImageUploader.defaultProps = {
  width: 200,
  height: 200,
};

export default AsyncImageUploader;
