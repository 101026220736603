import React, { useState } from 'react';

// Hooks
import { useTournament } from 'components/tournaments/TournamentPage';
import useAPI from 'components/hooks/useAPI';
import { useTranslation } from 'react-i18next';

// Changes the isInvisible property of a tournament
function TournamentVisibilityToggle() {
  const {
    publicId, currentPhase, isInvisible, setTournamentInvisible,
  } = useTournament();

  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState('');

  const { post } = useAPI();
  const { t } = useTranslation(['general', 'errors']);

  const toggleTournamentPrivate = async () => {
    try {
      setDisabled(true);
      setStatus('');

      const data = {
        isInvisible: !isInvisible,
      };

      await post(`/tournaments/visibility/${publicId}`, data);

      setTournamentInvisible(!isInvisible);
      setDisabled(false);
    } catch (e) {
      console.log(e);
      setDisabled(false);
      setStatus('errors:could_not_save_error');
    }
  };

  return (
    <div className="">

      <div className="is-relative">
        <input
          type="checkbox"
          id="tournamentVisibilityToggle"
          name="tournamentVisibilityToggle"
          className="switch is-rtl"
          checked={!isInvisible}
          onChange={toggleTournamentPrivate}
          disabled={disabled || currentPhase !== 'draft'}
        />
        <label
          htmlFor="tournamentVisibilityToggle"
          className="checkbox"
        >
          <p className="mb-1 ml-2 is-size-5 has-text-grey">
            {t('general:tournament_visibility')}
          </p>
        </label>
      </div>

      {
        status && (
        <p className="has-text-weight-semibold">
          {t(status)}
        </p>
        )
      }
    </div>
  );
}

export default TournamentVisibilityToggle;
