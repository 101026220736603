import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

// Assets
import defaultAvatar from 'assets/images/profile/default_avatar.png';
import defaultAvatarQuadratic from 'assets/images/profile/default_avatar_quadratic.png';

function CardPicDisplay({
  img = '',
  url = '/images/card_pictures/',
  quadratic = false,
}) {
  const urlToPic = `${process.env.REACT_APP_CDN_URL}${url}${img}`;

  const src = useMemo(() => {
    if (img) return urlToPic;

    if (quadratic) return defaultAvatarQuadratic;

    return defaultAvatar;
  }, [img, url, quadratic]);

  return (
    <div className="has-overflow-hidden p-0 br-5 is-flex has-content-centered">
      <img
        src={src}
        className="has-fullwidth has-fullheight"
        alt=""
      />
    </div>
  );
}

CardPicDisplay.propTypes = {
  img: PropTypes.string,
  url: PropTypes.string,
  quadratic: PropTypes.bool,
};

export default CardPicDisplay;
